/*@import url('https://fonts.googleapis.com/css2?family=Spartan:wght@100&display=swap');*/
@font-face {
  font-family: 'Spartan-Regular';
  src: local('Spartan-Regular'), url('../fonts/Spartan-Regular.ttf');
}
@font-face {
  font-family: 'Spartan-Bold';
  src: local('Spartan-Bold'), url('../fonts/Spartan-Bold.ttf');
}

.loader-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99999;
}
.loader-wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 120px;
  height: 120px;
  margin: -76px 0 0 -76px;
  color: white;
}
.searchBox {
  height: 100%;
  display: block;
  align-items: center;
  /* padding-top: 200px; */
}
.visible .menu .transition {
  background: #343434;
}
.item {
  color: white;
}
.card {
  width: 27rem !important;
  margin-top: 6rem;
  position: fixed;
  height: 9rem;
  overflow-y: scroll;
  cursor: pointer;
}
.Disciplines-title {
  padding-top: 1rem;
  padding-left: 1rem;
}
.accordion-button {
  border: none;
  background: none;
}
.accordion-button:focus {
  outline: none !important;
}
.accordion-header {
  font-family: "Spartan-Bold";
  font-size: 14px;
  margin-bottom: 10px;
}

.accordion-header button span p {
  font-family: "Spartan-Regular";
  font-size: 15px!important;

}

/* /////////////////////////////////////////// */
body {
  font-family: 'Spartan', sans-serif;
  background: black;
}
.search-form {
  display: block;
  width: 100%;
  max-height: 54px;
  min-height: 54px;
  transition: max-height 0.5s ease-out;
  overflow: hidden;
}
.search-form.show {
  max-height: 463px;
  transition: max-height 0.5s ease-in;
}

.browse-form {
  display: block;
  width: 100%;
  max-height: 54px;
  min-height: 54px;
  transition: max-height 0.5s ease-out;
  overflow: hidden;
}
.browse-form.show {
  max-height: 540px;
  /* transition: max-height 1s;
	-webkit-transition: max-height 0.5s; */
  transition-property: max-height;
  transition-duration: 0.5s;
}
.browse-form.show .input-grup input {
  background: #fff;
}

.tab-container {
  display: block;
  width: 100%;
  max-height: 0px;
  min-height: 0px;
  transition: max-height 0.5s ease-out;
  overflow: hidden;
  position: relative;
  border-radius: 15px;
}
.tab-container.show {
  max-height: 500px;
  transition: max-height 0.5s ease-in;
}
.browse-form .input-grup {
  position: relative;
}
.search-form .input-grup {
  position: relative;
}
.search-form .input-grup input {
  height: 54px;
  padding: 0 20px;
  border-radius: 25px;
  max-width: 100%;
  background: #b3b3b3;
  color: #393939;
  border: 0;
  font-size: 18px;
  font-family: Spartan-Bold;
  position: relative;
}

.search-form .input-grup input::placeholder {
  font-size: 18px!important;
  font-family: "Spartan-Bold"!important;
  color:#393939!important;
  position: absolute;
  top: 38%;
}

.search-form ul.icons-search {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  right: 10px;
  bottom: 50%;
  top: 50%;
}
.search-form ul.icons-search li:first-child {
  padding: 0px;
}
.search-form ul.icons-search li {
  padding: 0 13px 0 0px;
}
.search-form ul.icons-search li span {
  color: #393939;
  font-size: 24px;
}
.search-form ul.icons-search li a {
  color: #393939;
  font-size: 20px;
  cursor: pointer;
}
.search-dropdown {
  /*background: rgba(52, 52, 52, 0.6);*/
  background: #888888;
  border-radius: 0 0 20px 20px;
  margin: 0 0 20px;
  padding: 0px;
  height: 0px;
  overflow-y: scroll;
  margin-top: 3px;

  /* transition: transform 0.5s;
	transform: scaleY(0); */
}
.search-dropdown.show {
  /* z-index: 1; */
  /* height: 163px;
  padding: 20px;
  margin-top: -39px;
  padding-top: 50px; */
  /* transform: scaleY(1); */

  height: 340px;
  padding: 20px;
  margin-top: -14px;
  padding-top: 24px;
}
.search-form .input-grup {
  /* position: absolute;
	left: 0;
	right: 0; */
  /* z-index: 5; */
}
.search-dropdown ul {
  list-style: none;
  padding: 0;
  display: block;
  margin: 0;
}
.search-dropdown ul li {
  display: block;
  margin: 0px 0px 5px 0px;
  padding-top: 5px;
  border-top: 1.5px solid #eeeeee6b;
}
.search-dropdown ul li a {
  color: #fff;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  align-items: center;
}
.search-dropdown ul li span {
  color: #fff;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  align-items: center;
}
.search-dropdown ul li span {
  color: #fff;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  align-items: center;
  cursor: pointer;
}

.search-dropdown ul li span i {
  font-size: 21px;
}
.search-dropdown ul li span:hover {
  color: #fff;
}

.search-dropdown ul li span .desciplines-title {
  font-size: 15px;
  font-family: Spartan-Regular;
}
.search-form ul.icons-search.font-14 li {
  display: block;
  margin: 0 0 0;
  padding: 0 10px 0 10px;
}
.search-form ul.icons-search.font-14 li a {
  font-size: 12px;
  text-decoration: none;
  font-weight: 600;
}
.browse-form .input-grup input {
  height: 50px;
  padding: 0 20px;
  border-radius: 25px;
  max-width: 100%;
  background: #b3b3b3;
  color: #393939;
  border: 0;
  font-size: 18px;
  /*font-weight: 600;*/
  font-family: Spartan-Bold;
}
.browse-form .input-grup input::placeholder {
  font-size: 18px;
  font-family: "Spartan-Bold";
  /*font-weight: bold;;*/
  color: #393939!important;
  position:absolute;
  top: 36%;
}

.browse-form ul.icons-search {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  right: 25px;
  bottom: 45%;
  top: 55%;
}
.browse-form ul.icons-search li {
  padding: 0 13px 0 0px;
}
.browse-form ul.icons-search li:first-child {
  border-right: 1px solid #393939!important;
  padding: 0 13px 0 0px;
}
.browse-form ul.icons-search li span {
  color: #393939;
  font-size: 20px;
}
.browse-form ul.icons-search li a {
  color: #393939;
  font-size: 20px;
  cursor: pointer;
}

.browse-form ul.icons-search.font-14 li {
  display: block;
  margin: 0 0 0;
  padding: 6px  10px;
}
.browse-form ul.icons-search.font-14 li a {
  font-size: 16px;
  font-family: Spartan-Regular;
  text-decoration: none;
}

span.border-between {
  position: relative;
  top: 9px;
}
.search-dropdown strong {
  display: block;
  font-size: 16px;
  color: #fff;
  margin: 0 0 10px;
  /*padding-bottom: 10px;*/
  font-family: Spartan-Bold;
  font-weight: inherit
}
.location-holder {
  display: block;
  background: #fff;
  border-radius: 15px;
  overflow: hidden;
  padding: 16px 0px 26px 26px;
  border: 1px solid #eee;
  margin-top: 10px;
  margin-bottom: 45px;
  /* height: 350px; */
}
.tab-body-container {
  min-height: 70px;
  max-height: 200px;
  overflow-y: scroll;
  padding: 0px;
}
.tab-body-container::-webkit-scrollbar-track {
  background: linear-gradient(180deg, rgb(231, 231, 231) 0%, rgb(245, 243, 243) 90%);
}
.tab-body-container:hover {
  /* max-height: 200px;
	overflow-y: scroll; */
}
/* .location-holder:hover {
	overflow-y: scroll;
} */
.company-title {
  /*display: flex;*/
  margin: 0 0 10px;
}
.company-title .col-md-2{
  width: 45px!important;
}
.location-holder .company-title img {
  display: block;
  max-width: 40px;
  object-fit: cover;
  object-position: top;
  height: 25px;
}
.location-holder .company-title h3 {
  font-size: 18px;
  margin: 8px 0 0 10px;
  font-weight: 600;
}
.company-title.align-items-center h5 {
  font-size: 20px;
  font-family: Spartan-Bold;
  color: #393939;
  width: 80%;
  padding: 0px!important;
}
.company-address p {
  font-size: 15px;
  margin: 0 0 8px;
  padding: 0;
  color: #393939;
  font-family: Spartan-Regular;
}

.company-address p svg {
  margin: 0 5px 0 0;
  vertical-align: baseline;
}
.company-address {
  display: block;
  margin: 0 0 15px;
}
.panel-heading {
  padding: 0;
  border: 0;
}
.panel-title > a,
.panel-title > a:active {
  display: block;
  padding: 15px;
  color: #555;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  word-spacing: 3px;
  text-decoration: none;
}
.panel-heading a:before {
  font-family: 'Glyphicons Halflings';
  content: '\e114';
  float: right;
  transition: all 0.5s;
}
.panel-heading.active a:before {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
}
.accordion-item button {
  padding: 0;
  border: 0;
  font-size: 14px;
  font-weight: 400;
}
.accordion-button::after {
  width: 14px;
  height: 14px;
  background-size: 14px;
  margin-left: 5px!important;
  /*position: absolute;*/
  /*right: 0;*/
}
.accordion-item {
  border: 0;
  margin: 0 0 5px;
}
.accordion-item p {
  font-size: 14px !important;
}
.accordion-button:not(.collapsed) {
  color: #393939;
  background-color: #fff;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
  box-shadow: none !important;
  border: 0;
  padding: 0 0 5px;
  font-weight: 600;
  box-shadow: none !important;
}
.accordion-body {
  padding: 0;
  font-size: 12px;
}
ul.details-company {
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 14px;
}

ul.details-company li {
  display: block;
  overflow: hidden;
  /*margin-bottom: 15px;*/
  width: 100%;
  margin: 0px 0px 7px 0px;
}

ul.networks-details-company li {
  display: block;
  overflow: hidden;
  /*margin-bottom: 15px;*/
  width: 100%;
  margin: 10px 0px;
  padding: 0px 30px 0px 0px;
}

ul.networks-details-company li span {
  position: relative;
}
ul.networks-details-company li span p{
  width: 90%;
}
ul.networks-details-company li span i{
  position: absolute;
  top: 0;
  right: 0;
}

ul.details-company li span {
  /*font-family: 'Spartan-Regular';*/
  color: #393939;

}
ul.details-company li {
  display: block;
  overflow: hidden;
  width: 100%;
}

ul.details-company span.title-left {
  float: left;
  display: block;
  width: 40% !important;
}
ul.details-company span {
  width: 60%;
  float: left;
  overflow: hidden;
}
ul.partner-organizations {
  padding: 0;
  margin: 0;
  display: block;
  overflow: hidden;
  width: 100%;
  padding-right: 10px;
}
ul.partner-organizations li span {
  width: 90%;
  float: left;
  padding: 1px 0px;
  /*border-bottom: 1px solid gray;*/
}
ul.partner-organizations li i {
  float: right;
  display: block;
  padding: 4px 0px !important;
  position: absolute;
  top: 0;
  right: 0;
}
.location-holder h4 {
  font-size: 18px;
  font-family: "Spartan-Bold";
  margin: 0 0 16px;
  color: #393939;
  font-weight: 100!important;
  margin-left: -26px;
  padding-left: 26px;
  box-shadow: 0px 8px 9px -1px rgb(0 0 0 / 10%);
}
.location-tab {
  display: block;
  max-width: 100%;
  border: 1px solid #ddd;
  background: #ededed;
  margin: 0;
  border-radius: 0 0 20px 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.location-tab ul {
  display: flex;
  margin: 0 auto;
  padding: 0;
  text-align: center;
  display: flex;
}
.location-tab ul li {
  display: block;
  margin: 0;
  padding: 6px;
  text-align: center;
  border-right: 1px solid #dcdcdc;
  flex: 1;
  border-top: 1px solid #888;
}
.location-tab ul li:last-child {
  border-right: 0;
}
.location-tab ul li img {
  display: block;
  margin: 0 auto;
  max-width: 32px;
}
.location-tab ul li a {
  display: block;
  font-size: 14px;
  color: #393939;
  text-decoration: none;
  margin: 0;
}
.location-tab ul li span {
  display: block;
  font-size: 14px;
  color: #393939;
  text-decoration: none;
  margin: 0;
}

.location-tab ul li a p {
  display: block;
  font-size: 12px;
  color: #393939;
  text-decoration: none;
  margin: 0;
}
.location-tab ul li p {
  font-size: 16px;
}
.desciplines-details-hr {
  background: #eeeeee6b;
}
.close-desciplines-tab {
  width: 50px;

  border: 2px solid #fff;
  border-radius: 45px;
  opacity: 0.99;
  /* top: 70px; */
  cursor: pointer;
  position: absolute;
  bottom: -14px;
  left: 50%;
  transform: translate(-50%, 0);
}
.sidetab-container {
  position: absolute;
  bottom: 50px;
  left: 50px;
  width: 400px;
}
.partner-organizations:hover {
  color: black;
}
.header-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
  pointer-events: none;
}
.footer-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
}
.dot {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  display: inline-block;
  border-color: #fff;
  border: 2px solid #fff;
  margin: 0;
  margin-right: 5px;
  line-height: 45px;
  padding: 0;
  font-size: 18px;
  text-align: center;
  margin: 0 5px 10px;
}
.Zoom-in {
  color: white;
  margin-top: 0;
  margin-left: 0;
  cursor: pointer;
  margin: 0 0 5px;
}
.logo {
  margin-top: 25px;
  margin-left: 35px;
  pointer-events: initial;
}
.control-container {
  pointer-events: initial;
  position: absolute;
  right: 15px;
  bottom: 7px;
  text-align: center;
}
.overlay-container {
  overflow: hidden;
  height: 100vh;
  background: black;
}
.social-media {
  position: absolute;
  right: 15px;
  bottom: 7px;
  text-align: center;
}
.organization-link {
  color: black !important;
}
.logo-image {
  /* height: 100px; */
  height: auto;
  display: block;
  float: left;
}
.loading {
  position: absolute;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading svg circle {
  stroke-width: 2px;
  stroke-dasharray: 22;
  stroke: white;
}
.marker {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 0;
  width: 100vw;
  pointer-events: none;
}
.marker svg {
  width: 130px;
  height: 130px;
}
.marker svg circle {
  stroke-width: 2px;
  stroke-dasharray: 27;
  stroke: white;
}
.selected svg circle {
  stroke-width: 2px;
  stroke-dasharray: 223;
  stroke: greenyellow;
}
.info {
  /* background: gray; */
  /*margin-left: 125px;*/
}
.fa-info-circle {
  color: grey;
  font-size: 29px;
}
.info-tab {
  display: block;
  /* background: #fff; */
  border-radius: 15px;
  overflow: hidden;
  padding: 20px;
  /* border: 1px solid #eee; */
  margin-top: 10px;
  /* margin-bottom: 45px; */
  /* height: 370px; */
  position: absolute;
  width: 350px;
  margin-left: 30px;
  left: 360px;
  bottom: 0;
  font-size: 12px;
  padding-bottom: 0;
  margin-bottom: 0;
}
.bg-white {
  border: 1px solid #eee;
  background: #fff;
  border-radius: 15px;
  padding: 20px;
  max-width: 350px;
}
.info-title {
  font-size: 24px;
  font-weight: bolder;
  font-family: 'Lato';
}
.desciplines-loader {
  display: block;
  margin: 0 auto;
  text-align: center;
}
.desciplines-loader svg {
  height: 45px;
}
.desciplines-loader svg circle {
  stroke: white;
}
.fa-instagram {
  color: white;
  /* font-size: 21px; */
  padding-top: 6px;
  /* padding-left: 0px; */
  /* font-size: 17px; */
}
.fa-facebook-f {
  color: white;
  /* font-size: 21px; */
  padding-top: 6px;
  /* padding-left: 5px; */
}
.fa-linkedin-in {
  color: white;
  /* font-size: 21px; */
  padding-top: 6px;
  /* padding-left: 5px; */
}
.dot.info {
  display: inline-block;
  justify-content: center;
  align-items: center;
  /*background: #4c4c4c;*/
  border: 0;
}
.dot.info i {
  color: #fff;
}
.desciplines-title:hover {
  color: #fff;
}
.modal-backdrop {
  background-color: transparent;
}
.modal-body {
  margin-top: 30px;
}
.modal-header{
  padding: 12px 12px 0px 0px!important;
}
.modal-content {
  padding: 12px!important;
  border-radius: 15px  !important;
  font-size: 12px;
  margin-top: 0px!important;
  width: 70%!important;
  transform: translateX(50%);
}
.modal-body {
  font-size: 12px;
  margin-top: 0px!important;
}
.customize_model {
  /* width: 250px; */
  border-radius: 15px;
  font-size: 12px;
}
.close {
  position: absolute;
  right: 20px;
  top: 15px;
  font-size: 30px;
  color: rgb(156, 156, 156);
}
.search-dropdown ul li p {
  margin: 0 !important;
}
input::-webkit-input-placeholder {
  color: #000 !important;
}
input:-moz-placeholder {
  color: #000 !important;
}
.selectedTab {
  color: #104695;
}
.location-tab {
  box-shadow: 0px 4px 19px rgb(0 0 0 / 40%);
}
.selectedTabBorder {
  /* position: relative; */
  border-top: 2px solid #104695!important;
}
.selectedTabBorder:before {
  /* content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 2px;
    background: red;
    left: 0; */
}
.discipline-container .accordion-header {
  width: 100%;
}
.discipline-container .accordion-header button {
  width: 100%;
  text-align: left;
}
.discipline-container .accordion-header button i {
  margin-right: 10px;
}
.discipline-container .accordion-header button span {
  /*overflow: scroll;*/
}
.project-tab-container .accordion-header {
  border-bottom: 1px solid #e8e8e8;
}
.project-tab-container .accordion-header button {
  width: 100%;
  padding-bottom: 2px;
}
.accordion-body ul.partner-organizations.ml-0.pl-0 li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 0px 7px 0px;
  border-bottom: 1px solid gray;
  position: relative;
}
.project-tab-container .accordion-header button i {
  position: relative;
  top: 3px;
  font-weight: 400;
  font-size: 15px;
}
.project-tab-container .accordion-header button::after {
  position: relative;
  top: 3px;
  font-weight: 400;
  font-size: 15px;
  background: none!important;
}
ul.details-company > li:first-child {
  font-weight: 700;
}
ul.details-company > li:nth-of-type(2) {
  font-weight: 700;
}

.mobile-logo {
  max-width: 330.3px;
  float: left;
  transform: translateY(20%);
  margin-left: 10px;
}
.mobile-logo .logo-text {
  font-size: 30px;
  color: #d7d4d4;
  font-weight: bold!important;
}
.mobile-logo .logo-sologan {
  color: #ffffff;
  font-size: 15px;
  font-family: system-ui;
  font-weight: 100;
}
.mobile-info-container {
  /* margin-top: 17.4px; */
}
.info-tab-mobile {
  background: white;
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  display: block;
  border-radius: 0;
  font-size: 12px;
  bottom: 0;
  overflow: hidden;
  z-index: 99999;
  top: 0;
}
.info-tab-mobile .bg-white {
  border: none;
  background: #fff;
  border-radius: 0;
  padding: 20px;
  max-width: 350px;
}
.info-tab-mobile .close-icon {
  background-color: #dbdada;
  font-size: 15px;
  font-weight: bolder;
  color: #000;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  padding: 0 0;
  float: right;
  text-align: center;
  line-height: 35px;
}
.info-container{
  text-align: center;
}
.info-containerd{
  text-align: left!important;
}
@media screen and (max-width: 767px) {
  .header-container {
    margin-top: 17px;
    pointer-events: initial;
  }
  .close-desciplines-tab.toogle-card {
    width: 50px;
    bottom: 0;
    top: -35px;
    border-width: 4px;
  }
}
@media screen and (min-width: 574px) {
  .header-container {
    display: flex;
  }
  .info-container {
    display: block;
  }
  .social-media {
    display: block;
  }
  .sidetab-container {
    position: absolute;
    bottom: 50px;
    left: 50px;
    width: 400px;
  }
  .location-holder {
    border-radius: 15px;
  }
  .location-tab {
    border-radius: 0 0 20px 20px;
  }
  .tab-container {
    border-radius: 15px;
  }
}
@media screen and (max-width: 574px) {
  #cesiumContainer {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  .dot.info {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #4c4c4c;
    border: 0;
    left: 0;
    margin: 0 0px 0 0;
    float: right;
  }
  .dot {
    margin: 0 0 10px;
  }
  .sidetab-container {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0;
    width: 100%;
  }
  .location-holder {
    border-radius: 0;
  }
  .location-tab {
    border-radius: 0;
  }
  .tab-container {
    border-radius: 0;
  }
  .browse-form .input-grup input {
    border-radius: 0;
    font-size: 16px;
    font-weight: 700;
  }
  .location-holder {
    padding-bottom: 50px !important;
    overflow: hidden;
    margin: 0 !important;
  }
  .location-tab span.icon-holder {
    display: none !important;
  }
  .search-form {
    padding: 0 13.9px;
  }
  .search-form .input-grup input {
    font-size: 16px;
    font-weight: 700;
  }
  .browse-form ul.icons-search.font-14 li a {
    font-size: 16px;
    font-weight: 500;
  }
  .browse-form ul.icons-search {
    margin-right: 0px;
  }
  .company-title.align-items-center h5 {
    font-size: 16px;
    font-weight: 700;
  }
  .accordion-item p {
    font-size: 14px !important;
    font-weight: 700;
  }
  .logo {
    margin-top: 0;
    margin-left: 0;
  }
  .logo-image {
    height: auto;
    display: block;
    float: left;
  }
  .social-media {
    display: none;
  }
  .info-tab-mobile .bg-white {
    width: 100% !important;
    max-width: 100%;
  }
  /* .info-tab.info-container {
    left: 0;
    z-index: 99;
    margin: 0;
  }
  .info-tab.info-container {
    left: 0;
    z-index: 999;
    margin: 0;
    padding: 0;
    width: 100%;
    bottom: 0;
    top: 50px;
  }*/

}
.selected-browse a {
  font-family: "Spartan-Bold"!important;
  font-weight: bold;
}
.pl-0{
  padding-left: 0px!important;
}
.pl-20{
  padding-left: 20px!important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.discipline_header {
  margin-bottom: 10px !important;
}

.discipline_body {
  margin-top: 20px;
  margin-left: 15px;
}

.project_details li {
  margin: 15px 0px;
}

hr {
  margin-top: 0px !important;
  margin-bottom: 20px !important;
}

.control-container-right {

  position: absolute;
  right: 35px;
}

.pointer{
  cursor:pointer;
}
.info-box{
  color: black!important;
  font-family: Spartan;
}
.info-box h1{
  font-weight: 800;
}
.info-box-address{
  color: black!important;
}
.set-font div{
  font-family: Spartan;
  font-size: 12px;
}

.bg-transparent{
  background-color: rgb(221 210 210 / 23%)!important;
}
.dot-without-border{

  width: 40px;
  /*border-radius: 50%;*/
  display: inline-block;
  border-color: #fff;
  /*border: 2px solid #fff;*/
  margin: 0;
  margin-right: 5px;
  padding: 0;
  font-size: 18px;
  text-align: center;
  margin: 0px 2px!important;
}
span.border-between {
  position: relative;
  top: 5px;
  border-right: 1px solid;
  height: 80%;
}

.spartan-bold{
  font-family: "Spartan-Bold";
  color: #393939;
}

.spartan-regular{
  font-family: "Spartan-Regular";
  color: #393939;
}

.pointer-event{
  pointer-events: initial;
}
.location-tab ul li span:hover{
  cursor: pointer;
}
hr:not([size]){
  height: 0px;
}
.w-90{
  width: 90%!important;
}
.dotd{
  margin: 5px 0px;
}

